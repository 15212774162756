import { Button, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Header } from "../../common/Header";
import { Link } from "../../common/Link";

export default function ActionBanner() {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Stack py={8} width="100%" bgcolor="primary.extraLight" alignItems="center">
      <Grid
        container
        alignItems="center"
        gap={4}
        width={md ? "75%" : "90%"}
        maxWidth={1275}
        direction={md ? "row" : "column"}
        flexWrap="nowrap"
      >
        <Grid item md={6}>
          <Header
            title="We're ready when you are"
            desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua."
          />
        </Grid>
        <Grid item md={6}>
          <Link
            path="/contact-us"
            component={
              <Button variant="contained" size="large" sx={{ float: "right" }}>
                Get a Quote
              </Button>
            }
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
