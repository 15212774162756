import { ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Layout from "./pages/layout";
import theme from "./theme";
import { HelmetProvider } from "react-helmet-async";
import { useEffect, useState } from "react";
import sal from "sal.js";
import "sal.js/dist/sal.css";
import ContactUs from "./pages/contact-us";
import { useLocation } from "react-router-dom";
import AboutUs from "./pages/about-us";
import OurWork from "./pages/our-work";
import OurWorkSlug from "./pages/our-work-slug";

function App() {
  const { pathname } = useLocation();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [userHasScrolled, setUserHasScrolled] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      window.scrollY === 0
        ? setUserHasScrolled(false)
        : setUserHasScrolled(true);
    };
    if (isMenuVisible || modalVisible) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  });

  useEffect(() => {
    sal({
      threshold: 0.3,
    });
  }, [pathname]);

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route
            path="/"
            element={
              <Layout
                isMenuVisible={isMenuVisible}
                setIsMenuVisible={setIsMenuVisible}
                userHasScrolled={userHasScrolled}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
              />
            }
          >
            <Route index element={<Home setModalVisible={setModalVisible} />} />
            <Route path="/our-work" element={<OurWork />} />
            <Route path="/our-work/:slug" element={<OurWorkSlug />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
