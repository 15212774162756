import { createTheme } from "@mui/material";
import { blueGrey } from "@mui/material/colors";

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          color: blueGrey[900],
        },
        h1: {
          letterSpacing: "-5px",
          lineHeight: 1.2,
          fontSize: "72px",
        },
        h2: {
          letterSpacing: "-4px",
          lineHeight: 1.1,
        },
        h3: {
          letterSpacing: "-3px",
        },
        h4: {
          letterSpacing: "-2px",
        },
        h5: {
          letterSpacing: "-1px",
          lineHeight: 1.3,
        },
        h6: {
          letterSpacing: "-0.5px",
          lineHeight: 1.3,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
          textTransform: "none",
          letterSpacing: "-0.5px",
          boxShadow: "none",
          fontWeight: 500,
          "&:hover": {
            boxShadow: "none",
          },
        },
        sizeLarge: {
          padding: "16px 32px",
        },
        sizeMedium: {
          padding: "8px 24px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontWeight: 500,
        },
      },
    },
  },
  typography: {
    fontFamily: "Gilroy-Private",
    allVariants: {},
  },
  palette: {
    mode: "light",
    primary: {
      extraLight: "#FFE8E6",
      light: "#FF341A",
      main: "#FF1D00",
      dark: "#B31400",
      contrastText: "#ffffff",
    },
    white: {
      main: "#ffffff",
      contrastText: blueGrey[900],
    },
    black: {
      main: blueGrey[900],
      contrastText: "#ffffff",
    },
    text: {
      primary: blueGrey[900],
      secondary: blueGrey[300],
    },
    grey: blueGrey,
  },
});

export default theme;
