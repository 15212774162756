import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";


export const FeatureBlock = ({ icon, title, desc }) => {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Stack
      spacing={2}
      flex={1}
      maxWidth={300}
      textAlign={lg ? "start" : "center"}
      alignItems={lg ? "start" : "center"}
    >
      <img src={icon} width={64} height={64} alt={title} />
      <Box>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body2" color="text.secondary">
          {desc}
        </Typography>
      </Box>
    </Stack>
  );
};
