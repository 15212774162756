export const FOOTER_LINKS = {
  General: [
    {
      title: "About Us",
      path: "/about-us",
    },
    {
      title: "Our Work",
      path: "/our-work",
    },
    {
      title: "FAQ",
      path: "/#FAQ",
    },
  ],
  connect: [
    {
      title: "Contact Us",
      path: "/contact-us",
    },
    {
      title: "Google Form",
    },
  ],
};
