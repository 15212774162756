import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { Header } from "../../common/Header";

export default function Layout({ summary, title, desc, img }) {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Grid
      container
      maxWidth={1275}
      m="auto"
      pb={12}
      alignItems="center"
      width={sm ? "75%" : "90%"}
      direction={lg ? "row" : "column"}
      gap={4}
      flexWrap="nowrap"
    >
      <Grid item xs={12} lg={6} pt={4}>
        <Header type="page" summary={summary} title={title} desc={desc} />
      </Grid>
      <Grid item lg={6}>
        <Box data-sal="fade-up" data-sal-delay={100} pt={4}>
          <img src={img} alt="" width="100%" style={{ borderRadius: "8px" }} />
        </Box>
      </Grid>
    </Grid>
  );
}
