import { Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Place, RequestQuote } from "@mui/icons-material";
import { Link } from "../../../../common/Link";
import { LOCATION_URL } from "../../constants";

export const BtnGroup = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Stack
      direction={sm ? "row" : "column"}
      spacing={2}
      width={sm ? "auto" : "85vw"}
    >
      <Link
        path="/contact-us"
        component={
          <Button
            variant="contained"
            size="large"
            fullWidth={!sm}
            startIcon={<RequestQuote />}
          >
            Get a Quote
          </Button>
        }
      />

      <Link
        url={LOCATION_URL}
        component={
          <Button
            variant="outlined"
            size="large"
            color="white"
            fullWidth={!sm}
            startIcon={<Place />}
          >
            See location
          </Button>
        }
      />
    </Stack>
  );
};
