import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Link } from "../../../../common/Link";

export const TileCard = ({ img, title, desc }) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Link
      path={`/our-work/${title.toLowerCase().replaceAll(" ", "-")}`}
      component={
        <Box data-sal="fade-up">
          <Stack
            spacing={1}
            sx={{
              cursor: "pointer",
              "& img": {
                transition: "all ease 0.3s",
              },
              "&:hover img": { transform: "scale(1.2)" },
            }}
          >
            <Box overflow="hidden">
              <img src={img} alt="" width="100%" />
            </Box>
            <Typography variant="h6" textAlign="center">
              {title}
              {sm && (
                <Box display="inline" color="text.secondary">
                  {" - "}
                  {desc}
                </Box>
              )}
            </Typography>
          </Stack>
        </Box>
      }
    />
  );
};
