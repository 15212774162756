export const NAVBAR_LINKS = [
  {
    title: "home",
    path: "",
  },
  {
    title: "about us",
    path: "about-us",
  },
  {
    title: "our work",
    path: "our-work",
  },
  {
    title: "FAQ",
    path: "/#FAQ",
  },
];
