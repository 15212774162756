import { useParams } from "react-router-dom";
import Layout from "../sections/layout/Layout2Col";
import { PROJECTS } from "../sections/main/tile-grid/TileGrid/constants";

export default function OurWorkSlug() {
  const { slug } = useParams();

  const pathObj = PROJECTS.reduce((prev, { title, ...rest }) => {
    return Object.assign(prev, {
      [title.toLowerCase().replaceAll(" ", "-")]: { ...rest, title },
    });
  }, {});

  const { title, desc, details, img } = pathObj[slug];

  return <Layout summary={title} title={desc} desc={details} img={img} />;
}
