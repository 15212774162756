import { Stack } from "@mui/material";
import { Box } from "@mui/system";

export const LoaderAnimation = () => {
  return (
    <Stack
      bgcolor="black.main"
      borderRadius={2}
      height={80}
      width={80}
      alignItems="center"
      justifyContent="center"
      m="auto"
    >
      <Box className="loader"></Box>
    </Stack>
  );
};
