import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { FEATURES } from "./constants";
import { FeatureBlock } from "./FeatureBlock";

export default function Features() {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <Stack
      spacing={8}
      py={8}
      m="auto"
      maxWidth={1275}
      alignItems="center"
      direction={lg ? "row" : "column"}
      width={lg ? "75%" : "90%"}
    >
      {FEATURES.map(({ icon, title, desc }, idx) => (
        <React.Fragment key={idx}>
          <Box data-sal="zoom-in" data-sal-delay={100 + idx * 100}>
            <FeatureBlock icon={icon} title={title} desc={desc} />
          </Box>
          {idx < FEATURES.length - 1 &&
            (lg ? <KeyboardArrowRight /> : <KeyboardArrowDown />)}
        </React.Fragment>
      ))}
    </Stack>
  );
}
