import { Stack, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Link } from "../../Link";

export const NavLinkGroup = ({ links }) => {
  const { pathname } = useLocation();
  return (
    <Stack direction="row" spacing={4}>
      {links.map(({ title, url, path }, idx) => (
        <Link
          key={idx}
          url={url}
          path={path}
          component={
            <Typography
              variant="body2"
              textTransform="capitalize"
              color={
                pathname === "/" + path ? "text.primary" : "text.secondary"
              }
              sx={{ "&:hover": { color: "text.primary" } }}
            >
              {title}
            </Typography>
          }
        />
      ))}
    </Stack>
  );
};
