import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { BtnGroup } from "../BtnGroup";
import { NAVBAR_LINKS } from "../Navbar/constants";
import { Link } from "../Link";
import { Modal } from "../Modal";

export const Menu = ({ isMenuVisible, setIsMenuVisible }) => {
  const b1 = useMediaQuery("(max-width:1132px)");
  return (
    b1 && (
      <>
        <Modal open={isMenuVisible}>
          <Stack spacing={4} m="auto" width="90%">
            <Stack py={2}>
              {NAVBAR_LINKS.map(({ title, url, path }, idx) => (
                <Box key={idx} py={2} onClick={() => setIsMenuVisible(false)}>
                  <Link
                    url={url}
                    path={path}
                    component={
                      <Typography
                        variant="body2"
                        textTransform="capitalize"
                        color="text.secondary"
                        sx={{ "&:hover": { color: "text.primary" } }}
                      >
                        {title}
                      </Typography>
                    }
                  />
                </Box>
              ))}
            </Stack>
            <BtnGroup />
          </Stack>
        </Modal>
      </>
    )
  );
};
