import { Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Header } from "../../../common/Header";
import { PROJECTS } from "./constants";
import { TileCard } from "./TileCard";

export const TileGrid = () => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Stack
      spacing={6}
      m="auto"
      py={8}
      maxWidth={1275}
      width={md ? "75%" : "90%"}
      alignItems={md ? "start" : "center"}
    >
      <Header
        summary="Our work"
        title="Featured Projects"
        desc="Get a taste of what we do & how we do it"
      />
      <Grid container gap={md ? 4 : 2} flexWrap="nowrap">
        <Grid item xs={6}>
          <Stack spacing={6} width="100%">
            {PROJECTS.map(
              ({ img, title, desc }, idx) =>
                idx % 2 === 1 || (
                  <TileCard key={idx} img={img} title={title} desc={desc} />
                )
            )}
          </Stack>
        </Grid>
        <Grid item xs={6} mt={8}>
          <Stack spacing={6} width="100%">
            {PROJECTS.map(
              ({ img, title, desc }, idx) =>
                idx % 2 === 1 && (
                  <TileCard key={idx} img={img} title={title} desc={desc} />
                )
            )}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};
