import { Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "../Link";

export const BtnGroup = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Stack
      direction={sm ? "row" : "column"}
      spacing={2}
      width={sm ? "auto" : "90vw"}
    >
      <Link
        path="/contact-us"
        component={
          <Button
            variant="contained"
            color="primary"
            size={sm ? "medium" : "large"}
            fullWidth={!sm}
          >
            Get a Quote
          </Button>
        }
      />
    </Stack>
  );
};
