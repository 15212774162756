import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { Content } from "./Content";

export const Hero = ({ setModalVisible }) => {
  useEffect(() => {
    setModalVisible("loader");
  }, [setModalVisible]);

  return (
    <Box mx="auto">
      <Stack
        mx="auto"
        mb={4}
        width="95%"
        borderRadius={4}
        height="100vh"
        position="relative"
        overflow="hidden"
        sx={{
          "& > *": {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <Box maxWidth={900} zIndex={1}>
          <Content />
        </Box>
        <img
          src="/media/images/projects/banner.png"
          onLoad={() => setModalVisible(false)}
          alt=""
          style={{ zIndex: 0 }}
        />
      </Stack>
    </Box>
  );
};
