import { Stack } from "@mui/material";
import { LOGO } from "../../common/constants";
import { LoaderAnimation } from "./LoaderAnimation";

export const Loader = () => {
  return (
    <Stack
      gap={4}
      sx={{
        position: "absolute",
        top: "45%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <img src={LOGO} alt="logo" height="124px" />
      <LoaderAnimation />
    </Stack>
  );
};
