import { Outlet } from "react-router-dom";
import { Menu } from "../sections/common/Menu";
import { Navbar } from "../sections/common/Navbar";
import ActionBanner from "../sections/action-banner/ActionBanner";
import Footer from "../sections/footer/FooterMedium";
import { Loader } from "../sections/loader/LoaderFullScreen";
import { Modal } from "../sections/common/Modal";

export default function Layout({
  isMenuVisible,
  userHasScrolled,
  setIsMenuVisible,
  modalVisible,
  setModalVisible,
}) {
  return (
    <>
      <Navbar
        isMenuVisible={isMenuVisible}
        setIsMenuVisible={setIsMenuVisible}
        userHasScrolled={userHasScrolled}
      />
      <Menu isMenuVisible={isMenuVisible} setIsMenuVisible={setIsMenuVisible} />
      <Modal open={modalVisible}><Loader/></Modal>
      <Outlet />
      <ActionBanner />
      <Footer />
    </>
  );
}
