import { Button, Grid, useTheme, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { Header } from "../../common/Header";
import { Link } from "../../common/Link";
import { AccordionGroup } from "./AccordionGroup";

export const FAQ = () => {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [expanded, setExpanded] = useState("panel0");
  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Grid
      id="FAQ"
      container
      gap={8}
      py={12}
      maxWidth={1275}
      m="auto"
      flexWrap="nowrap"
      width={md ? "75%" : "90%"}
      direction={lg ? "row" : "column"}
      alignItems={lg ? "center" : md ? "start" : "center"}
    >
      <Grid item lg={6}>
        <Header
          summary="Help"
          title="Frequently Asked Questions"
          desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          maxWidth={448}
          cta={
            <Link
              path="/contact-us"
              component={
                <Button size="medium" variant="contained">
                  Contact us
                </Button>
              }
            />
          }
        />
      </Grid>
      <Grid item lg={6}>
        <AccordionGroup expanded={expanded} handleChange={handleChange} />
      </Grid>
    </Grid>
  );
};
