import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";

export const Header = ({ summary, title, desc, cta, maxWidth, type }) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box data-sal="header">
      {type === "page" ? (
        <Stack
          width="100%"
          spacing={2.5}
          maxWidth={maxWidth}
        >
          <Stack
            spacing={0.5}
            width="100%"
          >
            <Typography
              variant={sm ? "h6" : "body1"}
              textTransform="uppercase"
              fontWeight={300}
            >
              {summary}
            </Typography>
            <Typography variant={sm ? "h3" : "h4"} className="headline">
              {title}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              width="80%"
              className="subhead"
            >
              {desc}
            </Typography>
          </Stack>
          {cta}
        </Stack>
      ) : (
        <Stack
          width="100%"
          spacing={2.5}
          maxWidth={maxWidth}
          alignItems={md ? "start" : "center"}
        >
          <Stack
            spacing={0.5}
            width="100%"
            textAlign={md ? "left" : "center"}
            alignItems={md ? "start" : "center"}
          >
            <Typography
              variant={sm ? "h6" : "body1"}
              textTransform="uppercase"
              fontWeight={400}
            >
              {summary}
            </Typography>
            <Typography variant={sm ? "h3" : "h4"} className="headline">
              {title}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              width="80%"
              className="subhead"
            >
              {desc}
            </Typography>
          </Stack>
          {cta}
        </Stack>
      )}
    </Box>
  );
};
