import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { BtnGroup } from "./BtnGroup";

export const Content = () => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Stack spacing={4} alignItems="center" data-sal="header">
      <Stack
        textAlign="center"
        alignItems="center"
        justifyContent="center"
        width="90%"
      >
        <Typography
          variant={md ? "h1" : "h3"}
          color="white.main"
          className="headline"
        >
          Personalized interior decoration solutions
        </Typography>
        <Typography
          variant={md ? "body1" : "body2"}
          color="text.secondary"
          className="subhead"
        >
          Tailored to exceed your expectations
        </Typography>
      </Stack>
      <BtnGroup />
    </Stack>
  );
};
