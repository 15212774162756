import {
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Button } from "@mui/material";

export const ContactForm = () => {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <div data-sal="zoom-in" data-sal-delay="800">
      <form name="contact" method="POST" data-netlify="true">
        <input type="hidden" name="form-name" value="contact" />
        <Stack
          p={6}
          spacing={3}
          bgcolor="white.main"
          justifyContent="center"
          alignItems="center"
          borderRadius={2}
          width={lg ? "480px" : "100%"}
          sx={{
            boxShadow: "10px 10px 20px #eee",
            boxSizing: "border-box",
          }}
        >
          <Typography variant="h4">Fill out this form</Typography>
          <Stack direction="row" spacing={2}>
            <TextField
              label="First Name"
              name="first-name"
              variant="outlined"
              fullWidth
              required
            />
            <TextField
              label="Last Name"
              name="last-name"
              variant="outlined"
              fullWidth
              required
            />
          </Stack>
          <TextField
            label="Email"
            name="email"
            type="email"
            variant="outlined"
            fullWidth
            required
          />
          <TextField
            label="Number"
            name="number"
            type="number"
            variant="outlined"
            fullWidth
            required
          />
          <TextField
            label="Message"
            name="message"
            rows={4}
            variant="outlined"
            fullWidth
            multiline
            required
          />
          <Button type="submit" variant="contained" size="large" fullWidth>
            Send message
          </Button>
        </Stack>
      </form>
    </div>
  );
};
