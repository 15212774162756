import { useTheme } from "@emotion/react";
import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { LOGO } from "../../common/constants";
import { Link } from "../../common/Link";
import { FOOTER_LINKS } from "./constants";

export default function Footer() {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Stack m="auto" width="100%">
      <Grid
        container
        py={10}
        width={md ? "75%" : "90%"}
        m="auto"
        direction={md ? "row" : "column-reverse"}
        rowGap={12}
        maxWidth={1275}
      >
        <Grid item md={5}>
          <Stack
            spacing={6}
            textAlign={md ? "left" : "center"}
            alignItems={md ? "start" : "center"}
          >
            <Stack spacing={2} alignItems={md ? "start" : "center"}>
              <Link
                path="/"
                component={<img src={LOGO} alt="logo" height="64px" />}
              />
              <Typography variant="body1" color="text.secondary" width="80%">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </Typography>
            </Stack>
            <Stack direction="row" spacing={0.5} width="fit-content">
              <Typography variant="body1">©2022 Company —</Typography>
              <Typography variant="body1" color="text.secondary">
                All Rights Reserved
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        {md && <Grid item md={2} />}
        <Grid item md={5}>
          <Grid container direction={sm ? "row" : "column"}>
            {Object.keys(FOOTER_LINKS).map((category, idx) => (
              <Grid item xs={6} key={idx}>
                <Stack spacing={3} mt={4}>
                  <Typography variant="h6" textTransform="capitalize">
                    {category}
                  </Typography>
                  {FOOTER_LINKS[category].map(({ title, path, url }, idx) => {
                    return (
                      <Link
                        key={idx}
                        url={url}
                        path={path}
                        component={
                          <Typography
                            variant="body1"
                            color="text.secondary"
                            py={0.5}
                            sx={{
                              "&:hover": { textDecoration: "underline" },
                            }}
                          >
                            {title}
                          </Typography>
                        }
                      />
                    );
                  })}
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
}
