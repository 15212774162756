import {
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { Header } from "../../common/Header";
import { SocialLinks } from "../../common/SocialLinks";
import { ContactForm } from "./ContactForm";

export default function Contact() {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Stack width="100%" bgcolor="#f2f3f5">
      <Grid
        container
        width={md ? "75%" : "90%"}
        direction={lg ? "row" : "column"}
        mx="auto"
        my={6}
        gap={4}
        justifyContent="start"
        alignItems="center"
        maxWidth={1275}
        flexWrap="nowrap"
      >
        <Grid item lg={6}>
          <Stack spacing={4}>
            <Header
              summary="contact us"
              title="Get a quote"
              desc="We’re here to help. Drop your requirements in detail here so get back and assist you"
              maxWidth={420}
              cta={
                <>
                  <Typography variant="body1">
                    <Box display="inline-block" color="text.secondary">
                      or
                    </Box>{" "}
                    reach out to us on:
                  </Typography>
                  <SocialLinks />
                </>
              }
            />
          </Stack>
        </Grid>
        <Grid item lg={6}>
          <ContactForm />
        </Grid>
      </Grid>
    </Stack>
  );
}
