import { Masonry } from "@mui/lab";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { Header } from "../../common/Header";
import { Card } from "./Card";
import { TESTIMONIALS } from "./constants";

export const Testimonials = () => {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Stack width="100%" py={8} bgcolor="#f2f3f5">
      <Stack
        width={md ? "75%" : "90%"}
        alignItems={md ? "start" : "center"}
        m="auto"
        spacing={4}
        maxWidth={1275}
      >
        <Header
          summary="What clients say"
          title="Testimonials"
          desc="Don't just take my word for it, see what clients have to say about our services"
          maxWidth="100%"
        />

        <Masonry columns={lg ? 3 : md ? 2 : 1} spacing={2}>
          {TESTIMONIALS.map(({ name, occupation, review, image }, idx) => (
            <Box key={idx} data-sal="fade-up">
              <Card
                name={name}
                occupation={occupation}
                review={review}
                image={image}
              />
            </Box>
          ))}
        </Masonry>
      </Stack>
    </Stack>
  );
};
