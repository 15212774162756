import { Box } from "@mui/system";
import { useEffect, useState } from "react";

export const Modal = ({ children, open }) => {
  const [trackOpen, setTrackOpen] = useState(false);

  useEffect(() => {
    let timeout;
    if (open) setTrackOpen(open);
    else {
      if (trackOpen === "loader") {
        timeout = setTimeout(() => {
          setTrackOpen(false);
        }, 2000);
      } else {
        setTrackOpen(false);
      }
    }

    return () => clearTimeout(timeout);
  }, [open, trackOpen]);

  return (
    <Box
      bgcolor="white.main"
      className={trackOpen ? "open-nav" : "close-nav"}
      sx={{
        position: "fixed",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: trackOpen === "loader" ? 1000 : 99,
      }}
    >
      {children}
    </Box>
  );
};
