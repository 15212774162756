import { Helmet } from "react-helmet-async";
import { FAQ } from "../sections/faq/FAQSimple";
import Features from "../sections/features/Features";
import { Hero } from "../sections/hero/Hero1ColBg";
import { TileGrid } from "../sections/main/tile-grid/TileGrid";
import { Testimonials } from "../sections/testimonials/Tesimonials3Col";

export default function Home({ setModalVisible }) {
  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Hero setModalVisible={setModalVisible} />
      <Features />
      <TileGrid />
      <FAQ />
      <Testimonials />
    </>
  );
}
