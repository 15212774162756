export const SOCIAL_LINKS = [
  {
    platform: "instagram",
    icon: "/media/images/socials/instagram-logo.svg",
    url: "https://instagram.com",
  },
  {
    platform: "github",
    icon: "/media/images/socials/github-logo.svg",
    url: "https://github.com",
  },
  {
    platform: "discord",
    icon: "/media/images/socials/discord-logo.svg",
    url: "https://discord.com",
  },
];
