import { Menu, CloseOutlined } from "@mui/icons-material";
import { IconButton, Stack, useMediaQuery, useTheme } from "@mui/material";
import { NAVBAR_LINKS } from "./constants";
import { BtnGroup } from "../BtnGroup";
import { NavLinkGroup } from "./NavLinkGroup";
import { Link } from "../Link";
import { LOGO } from "../constants";

export const Navbar = ({ isMenuVisible, userHasScrolled, setIsMenuVisible }) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Stack
      width="100%"
      m="auto"
      bgcolor="white.main"
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 999,
        boxShadow: userHasScrolled && "0 0 50px rgb(23 92 255 / 10%)",
        transition: "all ease 0.3s"
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="90%"
        height="65px"
        m="auto"
        maxWidth={1400}
      >
        <Link
          path="/"
          component={<img src={LOGO} alt="logo" height="64px" />}
        />
        {md && <NavLinkGroup links={NAVBAR_LINKS} />}
        <Stack direction="row" spacing={2}>
          {sm && <BtnGroup />}
          {md || (
            <IconButton onClick={() => setIsMenuVisible(!isMenuVisible)}>
              {isMenuVisible ? <CloseOutlined /> : <Menu />}
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
